const jhonData = {
  name: "Michael G Alazar.",
  name2: "Richard Tea.",
  name3: "Alexa Johnson",
  role: "Full Stack Software Developer and Senior Civil Engineer,",
  ptext: "Creative Structural Designer & So ftware Developer located in Portland, OR.",
  socialLinks: [
    {
      name: "Facebook",
      url: "http://www.linkedin.com/",
      className: "social_facebook",
    },
    {
      name: "twitter",
      url: "http://www.twitter.com/",
      className: "social_twitter",
    },
    {
      name: "googleplus",
      url: "http://www.googleplus.com",
      className: "social_googleplus",
    },
    {
      name: "pinterest",
      url: "http://www.pinterest.com",
      className: "social_pinterest",
    },
  ],
  aboutme: "About Me",
  aboutdetails:
    "Proficient full stack JavaScript Developer with over 4 years of in-depth experience in web applications and building software solutions across the Finance and eCommerce domain. Able to design complete architecture for enterprise applications to streamline the development process in fast-paced environments. Sound knowledge of frontend and backend development with Java and JavaScript frameworks. Manifested ability in implementing industry best practices for frontend and backend applications through all phases of software development life cycle using advanced frameworks, design patterns, and demonstrated abilities in building RESTful web services mainly with react in separate business domains and projects.",
  service: [
    {
      id: 1,
      iconName: "icon-desktop",
      serviceTitle: "Web Design",
      sDetails:
        "",
    },
    {
      id: 2,
      iconName: "icon-pencil",
      serviceTitle: "Architect",
      sDetails:
        "",
    },
    {
      id: 3,
      iconName: "icon-presentation",
      serviceTitle: "Accounting",
      sDetails:
        "",
    },
    {
      id: 4,
      iconName: "icon-linegraph",
      serviceTitle: "Strategy",
      sDetails:
        "",
    },
    {
      id: 5,
      iconName: "icon-gears",
      serviceTitle: "Management",
      sDetails:
        "",
    },
    {
      id: 6,
      iconName: "icon-tools",
      serviceTitle: "Support",
      sDetails:
        "",
    },
  ],
  contact: [
    {
      id: "1",
      title: "Contact Info",
      menuItems: [
        {
          id: 1,
          icon: "icon_pin_alt",
          text: "Address: Portland Oregon, USA",
        },
        {
          id: 2,
          icon: "icon_phone",
          text: "Phone: +1.206.605.4534",
        },
        {
          id: 3,
          icon: "icon_mail",
          text: "Email: dcemg219@gmail.com",
        },
        {
          id: 4,
          icon: "icon_globe",
          text: "Website: https://michaelgalazar.com",
        },
      ],
    },
  ],
  devloperBanner: [
    {
      id: 1,
      subtile: "Front-End Developer",
      title: "Talk is cheap. \n Show me the code",
      discription:
        "I design and code beautifully simple things,\n and I love what I do.",
    },
  ],
  devAbout: [
    {
      id: 1,
      smalltitle: "Introduce",
      aHead: "Passion \n Heart & Soul",
      aHeadTwo: "Every great design begin with an even better story",
      adiscription:
        "Since beginning my journey as a freelancer designer nearly 4 years ago, I've done remote work for agencies, consulted for startups, and collaborated with talented people to create digital products for both business and consumer use, I am quietly confident, naturally curious.",
    },
  ],
  aboutSkill: [
    {
      id: 1,
      workName: "Design",
      text: "Create digital products with unique idea",
      complateProject: "20 Projects",
      icon: "icon-tools",
    },
    {
      id: 2,
      workName: "front-End",
      text: "My coding structure is clean and smooth",
      complateProject: "165 Projects",
      icon: "icon-genius",
    },
    {
      id: 3,
      workName: "SEO",
      text: "Boost your business with SEO optimize.",
      complateProject: "5 Projects",
      icon: "icon-linegraph",
    },
  ],
  awards: [
    {
      id: 1,
      image: "logo1.png",
      ctile: "Site of the year 2020",
      date: "April 2020",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 2,
      image: "logo2.png",
      ctile: "Theme of the day 2021",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
    {
      id: 3,
      image: "logo2.png",
      ctile: "Best design awwards",
      date: "March 2021",
      description: "For most favorited design voted by NY City Style customers",
      url: "http://www.pinterest.com",
      linktext: "view More",
    },
  ],
  contacttitle: "Contact Form",
};

export default jhonData;
