import React from "react";
//import emailjs from "emailjs-com";
import axios from "axios";

class Form extends React.Component {
  state = {
    data: {
      email: "",
      name: "",
      phone: "",
      subject: "",
      message: "",
    },
    submitted: false,
  };

  onChange = (e) => {
    return this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });
  };

  onSubmit = () => {
    console.log(JSON.stringify(this.state.data));
    const correctData = {};

    correctData.name = this.state.data.name;
    correctData.email = this.state.data.email;
    correctData.phone = this.state.data.phone;
    correctData.subject = this.state.data.subject;
    correctData.content = this.state.data.message;

    axios
      .post(
        "https://2n6pvrx5nk.execute-api.us-east-1.amazonaws.com/v1/contact",
        correctData
      )
      .then(
        (response) => {
          console.log("Response: " + JSON.stringify(response));
        },
        (error) => {
          console.log("Error " + error);
        }
      );
  };

  render() {
    
    return (
      <form onSubmit={this.onSubmit.bind(this)} className="form_class">
        <div className="row">
          <div className="col-lg-6">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Your Name*"
              onChange={this.onChange}
            />
    
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control"
              id="email"
              name="email"
              placeholder="Your Email*"
              onChange={this.onChange}
            />
  
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              id="subject"
              name="subject"
              className="form-control"
              placeholder="Subject*"
              onChange={this.onChange}
            />
           
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control"
              id="phone"
              name="phone"
              placeholder="Phone"
              onChange={this.onChange}
            />
         
          </div>
        </div>
        <textarea
          name="message"
          id="message"
          className="form-control"
          rows="6"
          placeholder="Your Message ..."
          onChange={this.onChange}
        ></textarea>
        <button type="submit" className="btn send_btn theme_btn">
          Send Message
        </button>
      </form>
    );
  }
}

export default Form;
